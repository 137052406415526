<template>
    <header>
        <div class="container">
            <router-link to="/dashboard" class="logo">
                <img src="@/assets/logo.png">
            </router-link>
            <div class="burger" @click="showMenu">
                <span></span>
                <span></span>
                <span></span>
            </div>
            <nav>
                <ul>
                    <li>
                        <router-link to="/dashboard" exact>Startseite</router-link>
                    </li>
                    <li v-if="loggedUser.admin">
                        <router-link to="/makros">Makros</router-link>
                    </li>
                    <li v-if="!loggedUser.qa">
                        <router-link to="/stats">Statistik</router-link>
                    </li>
                    <li>
                        <router-link to="/help">Hilfe</router-link>
                    </li>
                    <li v-if="!loggedUser.qa">
                        <router-link to="/settings">Einstellungen</router-link>
                    </li>
                    <li>
                        <button @click="logout">Ausloggen</button>
                    </li>
                </ul>
            </nav>
        </div>
        
    </header>
</template>

<style lang="scss" scoped>
    header {
        width: 100%;
        background: #f9fafb;
        box-shadow: 0px 4px 40px rgba(0,0,0,0.02);
        height: 8rem;   
        .container { 
            min-height: 8rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .logo {
            width: 5rem;
            img {
                width: 100%;
            }
        }
        .burger {
            display: none;
        }
        nav {
            ul {
                display: flex;
                list-style: none;
                li {
                    .router-link-active {
                        color: #4F98C1;
                        font-weight: bold;
                    }
                    margin-left: 11rem;
                    a, button {
                        font-size: 18px;
                        color: #1A1A1A;
                        text-decoration: none;
                    }
                    button {
                        border: none;
                        box-shadow: none;
                        background: none;
                        outline: none;
                        font-family: 'Open Sans', serif;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 576px) {
        header {
            transition: height .5s ease;
            .burger {
                display: inline-block;
                position: relative;
                height: 19px;
                width: 30px;
                span {
                    width: 30px;
                    height: 3px;
                    background: rgb(42, 42, 42);
                    position: absolute;
                    left: 0;
                    transition: all .3s ease;
                }
                span:first-child {
                    top: 0;
                }
                span:nth-child(2) {
                    top: 8px;
                }
                span:nth-child(3) {
                    top: 16px;
                }
                &.show {
                    span:last-child {
                        display: none;
                    }
                    span:first-child {
                        top: 8.5px;
                        transform: rotate(45deg);
                    }
                    span:nth-child(2) {
                        top: 8.5px;
                        transform: rotate(-45deg);
                    }
                }
            }
            nav {
                display: none;
            }
            nav ul li {
                opacity: 0;
                transition: all .3s ease;
            }
            nav.show {
                display: inline-block;
                position: absolute;
                left: 35px;
                top: 10rem;
                ul {
                    flex-direction: column;
                    li {
                        margin-left: 0px;
                        margin-bottom: 15px;
                        &:last-child {
                            margin-bottom: 0px;
                        }
                    }
                }
            }
            &.show {
                height: 45rem;
            }
        }
    }
</style>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    methods: {
        ...mapActions(['logout']),
        showMenu() {
            document.querySelector('nav').classList.toggle("show")
            document.querySelector('.burger').classList.toggle("show")
            document.querySelector('header').classList.toggle("show");
            const lis = document.querySelectorAll("header nav li");
            if(document.querySelector('header').classList.contains("show")) {        
                setTimeout(() => {
                    for(let i = 0; i < lis.length; i++) {
                        setTimeout(() => {
                            lis[i].style.opacity = 1;
                        }, 100)
                    }
                }, 300)   
            } else {
                setTimeout(() => {
                    for(let i = 0; i < lis.length; i++) {
                        setTimeout(() => {
                            lis[i].style.opacity = 0;
                        }, 100)
                    }
                }, 300)
            }
        }
    },
    computed: {
        ...mapGetters(['loggedUser'])
    }
}
</script>