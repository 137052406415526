<template>
    <main>
        <Header />
        <div class="container">
            <div class="heading mt-14 flex mb-10 justify-between items-center">
                <h2 class="font-bold text-3xl">{{ greeting }}, {{ loggedUser.name }}</h2>
                <div class="flex items-center">
                    <div class="flex items-center text-2xl mr-10">
                        <span class="mr-10">Alle suchen:</span> 
                        <form class="form-control" @submit.prevent="searchTickets" style="margin-bottom: 0px;">
                            <input type="text" v-model="search" style="min-height: 4rem" autocomplete="off">
                            <i class="fas fa-search" @click="searchTickets" style="top: 1.3rem; font-size: 14px"></i>
                        </form>
                    </div>
                    <p class="text-2xl mr-10" v-if="!loggedUser.admin && getTicketCount > 0">~ {{ getTicketCount }} Tickets</p>
                    <router-link to="/tickets/new" v-if="!loggedUser.admin && !loggedUser.qa" class="hover:bg-blue-300 transition text-2xl bg-blue-400 text-white font-bold py-3 px-5">
                    <i class="fas mr-3 fa-plus"></i>
                    Neues Ticket</router-link>
                </div>
            </div>
            <div class="bg-orange-200 text-2xl mr-4 flex justify-end" v-if="$route.name === 'Home'">
                <div class="mr-2">Summe:</div>
                <select v-model="amount" @change="updateAmount">
                    <option>20</option>
                    <option>50</option>
                    <option>100</option>
                </select>
            </div>
            <transition name="fadepage" mode="out-in">
                <router-view></router-view>
            </transition>
        </div>
    </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import Header from '@/components/Header.vue';
import Register from '@/components/Register.vue';
import Users from '@/components/Users.vue';
import Sidebar from '@/components/Sidebar.vue';
import Tickets from '@/components/Tickets.vue';

export default {
    data() {
        return {
            search: '',
            amount: 50
        }
    },
    components: {
        Header,
        Register,
        Users,
        Sidebar,
        Tickets
    },
    methods: {
        ...mapActions(['getTickets', 'getSolvedTickets', 'fetchTicketCount']),
        searchTickets() {
            this.$router.push(encodeURI(`/tickets/search?filter=${this.search.replace('#', '')}`));
        },
        updateAmount(){
            window.localStorage.setItem('per-page', this.amount);
            this.getTickets({ tag: this.loggedUser.tag, amount:this.amount });
        }
    },
    mounted() {
        if (window.localStorage.getItem('per-page')) {
            this.amount = parseInt(window.localStorage.getItem('per-page'));
        }
        if(this.loggedUser.tag) {
            this.fetchTicketCount(this.loggedUser.tag)
            this.getTickets({ tag: this.loggedUser.tag, amount:this.amount });
            this.getSolvedTickets(this.loggedUser.tag);
            if(!window.localStorage.getItem('favorite')) {
                window.localStorage.setItem('favorite', 'amazon');
            }
        }
    },
    computed: {
        ...mapGetters(['loggedUser', 'getTicketCount']),
        greeting() {
            const hours = new Date().getHours();
            if(hours < 12) {
                return 'Guten Morgen';
            } else if(hours >= 12 && hours <= 17) {
                return 'Guten Tag';
            } else {
                return 'Guten Abend';
            }
        }
    }
}
</script>